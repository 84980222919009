import {
  AcademicCapIcon,
  AdjustmentsIcon,
  ChartSquareBarIcon,
  CurrencyDollarIcon,
  CurrencyEuroIcon,
  CurrencyPoundIcon,
  CurrencyYenIcon,
  DocumentDuplicateIcon,
  DocumentIcon,
  TruckIcon,
  UserGroupIcon,
} from '@heroicons/react/outline';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { Helmet } from 'react-helmet';
import OfferSVG from '../components/atoms/OfferSVG';
import RoundBox from '../components/atoms/RoundBox';
import CTA, { CTAButton } from '../components/CTA';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

const FeaturesPage = () => {
  return (
    <Layout headerClass="bg-orange-100">
      <SEO title="Features" />
      <div className="bg-orange-100 py-20 md:py-40 relative">
        <div className="container">
          <div className="grid grid-cols-12 items-center gap-y-8">
            <div className="col-span-12 lg:col-span-5 lg:col-start-2">
              <h1 className="heading-sm mb-8">
                Få det salgs&shy;overblik som du mangler idag
              </h1>
              <p className="sub">
                Skab orden i dit salg med et overblik over dine tilbud, ordrer
                og faktura
              </p>
              <div className="inline-flex">
                <CTAButton className="mt-8" />
              </div>
            </div>
            <div className="col-span-12 md:col-span-6">
              <StaticImage
                src="../images/hand-shake.png"
                alt=""
                placeholder="blurred"
                layout="fullWidth"
              />
            </div>
          </div>
        </div>

        <svg
          viewBox="0 0 1440 28"
          version="1.1"
          className="w-full absolute bottom-0"
        >
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(0.000000, -841.000000)" fill="#FFFFFF">
              <polygon points="1440 869 1270 859.920598 1198 869 1051 850.841197 892 859.920598 641 841.761795 492 859.920598 368 859.920598 234 841.761795 153 859.920598 0 869" />
            </g>
          </g>
        </svg>
      </div>

      <div className="container grid grid-cols-12 py-32">
        <div className="col-span-12 lg:col-span-2 mb-4">
          <RoundBox>
            <DocumentDuplicateIcon className="w-10 h-10 text-light-blue-500" />
          </RoundBox>
        </div>
        <div className="col-span-12 lg:col-span-8">
          <h2 className="heading-sm">Opret tilbud, ordrer og faktura</h2>
          <p className="sub my-6">
            Lav lynhurtigt tilbud, modtag ordre og send faktura til dine kunder.
            Sails sender fakturaen videre til automatisk bogføring i dit
            økonomisystem (f.eks. e-conomic).
          </p>
          <Link to="" className="link">
            <AcademicCapIcon className="w-6 h-6 mr-2 flex-shrink-0" />
            <span>
              Lær hvordan du opretter tilbud, ordrer og faktura med Sails
            </span>
          </Link>
        </div>

        <div className="col-span-12 lg:col-span-10 lg:col-start-2 grid grid-cols-2 md:grid-cols-4 gap-4 mt-16">
          <div className="col-span-2 row-span-2 bg-light-blue-100 py-8 flex items-center justify-center rounded">
            <OfferSVG className="shadow-lg rounded-md bg-white w-1/2 h-auto" />
          </div>
          <div className="bg-light-blue-100 pt-full relative rounded">
            <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
              <p className="sub font-bold text-light-blue-500">Flere valuta</p>
            </div>
          </div>
          <div className="bg-light-blue-100 pt-full relative rounded">
            <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
              <div className="inline-grid grid-cols-2 text-light-blue-500">
                <CurrencyEuroIcon className="w-10 h-10" />
                <CurrencyDollarIcon className="w-10 h-10" />
                <CurrencyPoundIcon className="w-10 h-10" />
                <CurrencyYenIcon className="w-10 h-10" />
              </div>
            </div>
          </div>
          <div className="bg-light-blue-100 pt-full relative rounded">
            <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
              <AdjustmentsIcon className="w-20 h-20 text-light-blue-500" />
            </div>
          </div>
          <div className="bg-light-blue-100 pt-full relative rounded">
            <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
              <p className="sub font-bold text-light-blue-500 text-center">
                Integrer med e-conomic
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-light-blue-50 py-32 mb-28 lg:mb-64">
        <div className="container grid grid-cols-12">
          <div className="col-span-12 lg:col-span-2 mb-4">
            <RoundBox>
              <UserGroupIcon className="w-10 h-10 text-light-blue-500" />
            </RoundBox>
          </div>
          <div className="col-span-12 lg:col-span-8">
            <h2 className="heading-sm">Bedre overblik over dine kontakter</h2>
            <p className="sub my-6">
              Hold styr på dine kunder og leverandører med et enkelt overblik.
              Opret automatisk danske samt øvrige europæiske virksomheder og
              kontakter ud fra CVR-nummer.
            </p>
            <Link to="" className="link">
              <AcademicCapIcon className="w-6 h-6 mr-2 flex-shrink-0" />
              <span>
                Lær hvordan du får et bedre overblik over dine kontakter med
                Sails
              </span>
            </Link>
          </div>
          <div className="col-span-12 lg:col-span-10 lg:col-start-2 justify-center -mb-60 lg:-mb-96 mt-16">
            <StaticImage
              src="../images/contacts-card.png"
              alt="Kontaker i Sails"
              className="max-w-full"
              placeholder="blurred"
              layout="fullWidth"
            />
            <StaticImage
              src="../images/contacts-card-alt.png"
              alt="Kontaker i Sails"
              className="max-w-full"
              placeholder="blurred"
              layout="fullWidth"
            />
          </div>
        </div>
      </div>

      <div className="container grid grid-cols-12 py-32">
        <div className="col-span-12 lg:col-span-2 mb-4">
          <RoundBox>
            <TruckIcon className="w-10 h-10 text-light-blue-500" />
          </RoundBox>
        </div>
        <div className="col-span-12 lg:col-span-8">
          <h2 className="heading-sm">
            Leverandør&shy;priser, direkte indkøb og lagerstyring
          </h2>
          <p className="sub my-6">
            Få overblik over dine varers dagspriser og lagertal med import af
            leverandørprislister. Med Sails kan du oprette og sende dine
            indkøbsordre med ét klik, enten direkte til kunden eller forbi dit
            eget lager.
          </p>
          <Link to="" className="link">
            <AcademicCapIcon className="w-6 h-6 mr-2 flex-shrink-0" />
            <span>
              Lær hvordan du opretter tilbud, ordrer og faktura med Sails
            </span>
          </Link>
        </div>

        <div className="col-span-12 lg:col-span-8 lg:col-start-3 mt-16">
          <StaticImage
            src="../images/cost-prices.png"
            alt="Import af leverandørpriser"
            className="max-w-full"
            placeholder="blurred"
            layout="fullWidth"
          />
        </div>
      </div>

      <div className="bg-light-blue-50 py-32 mb-28 lg:mb-64">
        <div className="container grid grid-cols-12">
          <div className="col-span-12 lg:col-span-2 mb-4">
            <RoundBox>
              <UserGroupIcon className="w-10 h-10 text-light-blue-500" />
            </RoundBox>
          </div>
          <div className="col-span-12 lg:col-span-8">
            <h2 className="heading-sm">
              Hold styr på dine opgaver og registrer tid
            </h2>
            <p className="sub my-6">
              Med registrering af indkomne eller planlagte opgaver går intet i
              glemmebogen. Registrer opgaver, tidsforbrug og fakturer din kunde.
              Vi opdaterer dagligt valutakurser så du altid har den rette
              konvertering.
            </p>
            <Link to="" className="link">
              <AcademicCapIcon className="w-6 h-6 mr-2 flex-shrink-0" />
              <span>
                Lær hvordan du laver opgaver og registrerer tid med Sails
              </span>
            </Link>
          </div>
          <div className="col-span-12 lg:col-span-10 lg:col-start-2 justify-center -mb-60 lg:-mb-96 mt-16">
            <StaticImage
              src="../images/tasks.png"
              alt="Kontaker i Sails"
              className="max-w-full"
              placeholder="blurred"
              layout="fullWidth"
            />
          </div>
        </div>
      </div>

      <div className="container grid grid-cols-12 py-32">
        <div className="col-span-12 lg:col-span-2 mb-4">
          <RoundBox>
            <CurrencyEuroIcon className="w-10 h-10 text-light-blue-500" />
          </RoundBox>
        </div>
        <div className="col-span-12 lg:col-span-8">
          <h2 className="heading-sm">Arbejd med flere forskellige valuta</h2>
          <p className="sub my-6">
            Lad dig ikke begrænse af en enkelt valuta. Sails lader dig arbejde
            med alle de valutatyper du og din kunde ønsker.
          </p>
          <Link to="" className="link">
            <AcademicCapIcon className="w-6 h-6 mr-2 flex-shrink-0" />
            <span>
              Lær hvordan du bruger flere forskellige valuta med Sails
            </span>
          </Link>
        </div>
      </div>

      <div className="container grid grid-cols-12 py-32">
        <div className="col-span-12 lg:col-span-2 mb-4">
          <RoundBox>
            <ChartSquareBarIcon className="w-10 h-10 text-light-blue-500" />
          </RoundBox>
        </div>
        <div className="col-span-12 lg:col-span-8">
          <h2 className="heading-sm">Mål din virksomheds performance</h2>
          <p className="sub my-6">
            Sails giver dig et samlet overblik over din virksomheds performance
            for en given periode. Se også den enkelte sælgers omsætning,
            dækningsbidrag og tilgang af kunder. Opstil salgsmål og følg
            udviklingen.
          </p>
          <Link to="" className="link">
            <AcademicCapIcon className="w-6 h-6 mr-2 flex-shrink-0" />
            <span>
              Lær hvordan du måler din virksomheds performance med Sails
            </span>
          </Link>
        </div>
      </div>

      <CTA />
    </Layout>
  );
};

export default FeaturesPage;
