import { ArrowRightIcon } from '@heroicons/react/solid';
import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { ComponentPropsWithoutRef } from 'react';

const CTA = () => {
  return (
    <div className="w-full bg-cyan-900 py-32">
      <div className="container">
        <div className="max-w-2xl mx-auto">
          <h2 className="heading text-cyan-50">
            Tag handling.
            <br />
            Opnå mere.
          </h2>
          <p className="text-lg lg:text-xl text-cyan-50 my-12">
            Vores kunder elsker Sails, fordi det forsimpler deres salgsarbejde
            og ikke står i vejen. Det er for alle handelsvirksomheder der ønsker
            bedre resultater. Lyder det som dig? Så lad os komme igang!
          </p>
          <div className="flex items-start">
            <CTAButton className="mr-4" dark />
            <Link to="/contact">
              <div className="btn-secondary">Snak med os</div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export const CTAButton = ({
  dark = false,
  ...props
}: ComponentPropsWithoutRef<'div'> & { dark?: boolean }) => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          appUrl
        }
      }
    }
  `);
  return (
    <div {...props}>
      <a href={`${site.siteMetadata.appUrl}/auth/sign-up`} className="btn">
        Kom igang nu <ArrowRightIcon className="ml-2 h-5 w-5" />
      </a>
      <p
        className={`text-tiny text-center mt-1 ${
          dark ? 'text-gray-200' : 'text-gray-500'
        }`}
      >
        30 dage gratis - kræver ikke kreditkort
      </p>
    </div>
  );
};

export default CTA;
