import React, {ComponentPropsWithoutRef} from 'react';

const RoundBox = ({
  children,
  boxProps,
  ...props
}: ComponentPropsWithoutRef<'div'> & {
  boxProps?: ComponentPropsWithoutRef<'svg'>;
}) => {
  return (
    <div {...props} className={'relative w-20 h-20' + (props.className || '')}>
      <svg
        width="80px"
        height="80px"
        viewBox="0 0 80 80"
        version="1.1"
        className="fill-current text-light-blue-100"
        {...boxProps}
      >
        <g stroke="none" strokeWidth="1" fillRule="evenodd">
          <g transform="translate(-294.000000, -2413.000000)">
            <g transform="translate(294.000000, 2413.000000)">
              <path
                d="M30.7666224,-1.24300837e-15 L49.2333776,1.24300837e-15 C59.9316025,-7.22223659e-16 63.8110373,1.11390733 67.7221484,3.20559245 C71.6332596,5.29727756 74.7027224,8.36674045 76.7944076,12.2778516 C78.8860927,16.1889627 80,20.0683975 80,30.7666224 L80,49.2333776 C80,59.9316025 78.8860927,63.8110373 76.7944076,67.7221484 C74.7027224,71.6332596 71.6332596,74.7027224 67.7221484,76.7944076 C63.8110373,78.8860927 59.9316025,80 49.2333776,80 L30.7666224,80 C20.0683975,80 16.1889627,78.8860927 12.2778516,76.7944076 C8.36674045,74.7027224 5.29727756,71.6332596 3.20559245,67.7221484 C1.11390733,63.8110373 4.81482439e-16,59.9316025 -8.28672246e-16,49.2333776 L8.28672246e-16,30.7666224 C-4.81482439e-16,20.0683975 1.11390733,16.1889627 3.20559245,12.2778516 C5.29727756,8.36674045 8.36674045,5.29727756 12.2778516,3.20559245 C16.1889627,1.11390733 20.0683975,7.22223659e-16 30.7666224,-1.24300837e-15 Z"
                id="Rectangle"
              />
            </g>
          </g>
        </g>
      </svg>
      <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center">
        {children}
      </div>
    </div>
  );
};

export default RoundBox;
